import React from 'react';
import { graphql } from 'gatsby';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <>
    <SEO title={frontmatter.title} description={frontmatter.description} />
    <Layout
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      button={frontmatter.button}
    >
      <section
        className="page-section index-section-two bg-primary text-light"
        id="steps"
        name="steps"
      >
        <Container>
          <h2
            className="text-center pt-5"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            How it works
          </h2>
          <p
            className="text-secondary text-center pb-5"
            style={{ fontWeight: 300 }}
          >
            Access the Catalyst Connector for free and authorize your Facebook Ads credentials to access your data in no time.
          </p>
          <br />
          <Row>
            <Col className="p-5 stepDownload" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/download-step@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 1
                </p>
              </Row>
              <Row className="justify-content-center">
                <Button variant="secondary" href="#pricing">
                  Download for free&nbsp;&nbsp;
                  <img src="/img/download-button-icon@2x.png" />
                </Button>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-1@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 2
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open Tableau
				  <br />
				  (2019.4 and later)
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-2@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 3
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open the web data connector link in Tableau{' '}
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-3@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 4
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Select your Facebook Ads data
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="page-section" style={{ backgroundColor: '#E9EFF6' }}>
        <Container className="mb-4" style={{ marginTop: 64 }}>
          <Row>
            <Col className="index-section-one-left text-center" xs={12} lg={3}>
              <img src="/img/security@3x.png" />
            </Col>
            <Col className="ml-lg-5" xs={12} lg>
              <h2 style={{ fontSize: 62, fontWeight: 'bold' }}>
                Utmost security and user privacy - on your own terms
              </h2>
              <p style={{ fontWeight: 300 }}>
                <p style={{ fontSize: 25 }}>
                  <b>Can anyone see your imported data? No, not even us.</b>
                </p>
                <br />
                Ironclad security is a Daexus staple. We don't save your data,
                we don’t touch your data...we can’t even see your data. We’re
                fanatical about data security and this is one of the forces that
                drive us as a company. That’s why we go to extra lengths to make
                our tools exceptionally secure: the data only flows between
                Facebook Ads and your computer - and nowhere in between (no
                other server involved).
              </p>
              <br />
              <Button variant="secondary" href="#pricing">
                Try it out now&nbsp;&nbsp;
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="page-section products-features">
        <Container>
          <h2
            className="text-center pt-5"
            style={{
              fontSize: 62,
              fontWeight: 'bold',
            }}
          >
            Features
          </h2>
          <div
            style={{
              backgroundColor: '#edca21',
              height: 2,
              width: 80,
              margin: '16px auto',
            }}
          ></div>
          <p className="text-center text-dark pb-5" style={{ fontWeight: 300 }}>
            Facebook Ads Connector for Tableau
          </p>
          <Row>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Safe from any prying eyes</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                The Catalyst Connector brings Facebook Ads data directly to
                your computer. Unlike with other products, the data does not
                flow through third party servers. Your data is for your eyes and
                your eyes only so we’re making sure it stays this way.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>No rush, no pressure</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                The Catalyst Connector is free to use for as long as you want!
                We want you to be able to focus on getting insights from the
                data rather than wasting time figuring how to retrieve it.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>
                Painless installation &
                <br />
                effortless navigation
              </h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                One-time point & click installation and user-centered UI.
                <br />
                Minimal effort for maximum value and impact
                <br />
                Hassle free set-up guide included
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>No price on peace of mind</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                Free or as little as $24.99 for extra features.
                <br />
                <br />
                <b>How do we keep our prices so competitive?</b>
                <br />
                We made it our mission to be actively conscious about our
                product's resource consumption. To maximize efficiency, we took
                a frugal approach and decided against using any external servers
                that process data.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Ironclad Security</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                We don't save your data, we don’t touch your data...
                <span style={{ fontWeight: 700 }}>
                  we can’t even see your data.
                </span>
                <br />
                <br />
                Rest assured, we apply a strict HANDS OFF policy when it comes
                to data, as our tool imports it from Facebook Ads straight to
                your computer. More so, since the Catalyst connector runs
                entirely on your computer it minimizes the chances of any
                unauthorized access.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>
                Sky’s the limit
                <br />
                (unlimited data imports)
              </h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                We don't believe in limits when it comes to importing data, so
                our server plan includes:
                <br />
                <br />- Easy access to Facebook Ads
                <br />- Painless installation and easy set-up
                <br />- Clear installation guide and tech support
                <br />- Ability to extract data from multiple accounts
                <br />- User friendly experience
                <br />- Email support
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="page-section bg-secondary text-dark">
        <Container>
          <h2
            className="text-center py-5 text-primary"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            Premium features
          </h2>
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/time-saver@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Time
                  <br />
                  saver
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Whether you need to refresh your data sources or other
                  dashboards, no need to type in your Facebook Ads
                  credentials.
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/dates@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Rolling
                  <br />
                  dates
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  By using rolling dates your dashboards are always ready to
                  provide the most relevant data.
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/updates@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Scheduled
                  <br />
                  refreshes
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Always have up-to-date reports by scheduling automated refreshes
                  in Tableau Server or Tableau Online.
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/control@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Complete
                  <br />
                  control
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Manage users, seats, credentials or even host the app on
                  your own dedicated server.
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/unlimited@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Unlimited
                  <br />
                  usage
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Achieve a 360 view with <b>unlimited</b>:
                </p>
              </Row>
              <Row className="justify-content-center">
                <ul
                  style={{
                    padding: 0,
                    fontWeight: 300,
                  }}
                >
                  <li>number of rows</li>
                  <li>number of reports</li>
                  <li>Facebook Ads credentials</li>
                </ul>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/managers-icon@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Manage accounts
                  <br />
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Invite your team to your Daexus account. Add as many seats as
                  needed and only pay for what you use (charge per seat).
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="page-section" style={{ backgroundColor: '#E9EFF6' }}>
        <Container className="mb-5" style={{ marginTop: 64 }}>
          <h2
            style={{ fontSize: 62, fontWeight: 'bold' }}
            className="justify-content-center text-center pt-7"
          >
            Which app to use?
          </h2>
          <Row className="px-4">
            <Col xs>
              <p
                style={{ fontWeight: 300 }}
                className="justify-content-left text-center"
              >
                <br />
                When purchasing a plan, premium access to both app types
                (desktop and online) is included.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center text-center pt-5">
            <table className="appcomparisontable">
              <tr>
                <th>Features</th>
                <th>Daexus Catalyst - Desktop app</th>
                <th>Daexus Catalyst - Online app</th>
              </tr>
              <tr>
                <td>App runs without download</td>
                <td>No</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Data Imports without local app</td>
                <td>No</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Supports hosting on client’s own servers</td>
                <td>Yes</td>
                <td>No</td>
              </tr>
              <tr>
                <td>Secure</td>
                <td>Yes</td>
                <td>Yes (we wouldn’t have it any other way)</td>
              </tr>
              <tr>
                <td>Free Version</td>
                <td>Always</td>
                <td>Always</td>
              </tr>
              <tr>
                <td>Supports Premium Features</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
            </table>
          </Row>
        </Container>
      </section>
      <section className="page-section index-section-two bg-primary text-light">
        <Container className="my-5">
          <Row>
            <Col xs={12} lg className="text-center">
              <img src="/img/bubels@2x.png" />
            </Col>
            <Col className="ml-lg-5" xs={12} lg>
              <h2 style={{ fontSize: 62, fontWeight: 'bold' }}>
                We’ve got your back
              </h2>
              <p style={{ fontWeight: 300 }}>
              Check out our {' '}
                <a
                  href="https://daexus.helpsite.com/"
                  target="_blank"
                  style={{color: '#ffffff', textDecoration: 'underline' }}
                >
                  Support Center
                </a>{' '}for tutorials on how to make the most of our connector and reach out to our dedicated support team if you need help; 
                <br />Use the chat box at the bottom of the screen or send us an email at{' '}
                <a
                  href="mailto:support@daexus.io"
                  style={{ color: '#ffffff', textDecoration: 'underline' }}
                >
                  support@daexus.io
                </a>
                <br />
                <strong>We’ll try to get back to you soon as we can!</strong>
              </p>
              <br />
              <Button variant="secondary" href="#pricing">
                Try it out now&nbsp;&nbsp;
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="page-section products-pricing"
        id="pricing"
        name="pricing"
      >
        <Container>
          <h2
            className="text-center pt-5"
            style={{
              fontSize: 62,
              fontWeight: 'bold',
            }}
          >
            Pricing
          </h2>
          <h4
            className="text-center text-dark"
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: 2.5,
            }}
          >
             Facebook Ads Catalyst Connector
          </h4>
          <Row className="text-dark">
            <Col xs={12} lg={4}>
              <div
                style={{
                  backgroundColor: '#EAEBED',
                  borderRadius: 5,
                  height: 'calc(100% - 3rem)',
                }}
                className="pt-4 my-4"
              >
                <h4 className="text-center" style={{ fontWeight: 'bold' }}>
                  FREE
                </h4>
                <hr style={{ borderWidth: 1 }} />
                <p style={{ fontSize: 56, fontWeight: 500 }} className="pl-4">
                  $--/--
                </p>
                <ul>
                  <li>
                    Highly secure: The app can't be accessed from outside your
                    computer
                  </li>
                  <li>No sign up required</li>
                  <li>Unlimited data imports</li>
                  <li>Access to unlimited Facebook Ads accounts</li>
                  <li>Email support</li>
                </ul>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="text-center pb-5 products-download">
                  <Button
                    variant="outline-primary"
                    href="https://api.daexus.io/download"
                    id="downloadButton"
                  >
                    Download now&nbsp;&nbsp;
                    <img src="/img/download-button-icon@2x.png" />
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div
                style={{ borderRadius: 5 }}
                className="pt-4 my-4 bg-primary text-light"
              >
                <h4
                  className="text-center text-secondary"
                  style={{ fontWeight: 'bold' }}
                >
                  PRO
                </h4>
                <hr style={{ backgroundColor: '#ffffff', borderWidth: 1 }} />
                <p style={{ fontSize: 56, fontWeight: 500 }} className="pl-4">
                  $9.99
                  <span style={{ fontSize: 16, fontWeight: 300 }}>/mo</span>
                </p>
                <ul>
                  <li>
                    <b>Saved credentials</b>
                  </li>
                  <li>
                    Highly secure: The app can't be accessed from outside your
                    computer
                  </li>
                  <li>Unlimited data imports</li>
                  <li>Access to unlimited Facebook Ads accounts</li>
                  <li>Email support</li>
                  <br />
                  <br />
                  <br />

                </ul>
                <div className="text-center py-5">
                  <Button
                    variant="secondary"
                    href="https://profile.daexus.io/signup/fbads?plan=PRO"
                  >
                    Sign up now
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div
                style={{ borderRadius: 5 }}
                className="pt-4 my-4 bg-secondary"
              >
                <h4
                  className="text-center text-primary"
                  style={{ fontWeight: 'bold' }}
                >
                  SERVER
                </h4>
                <hr style={{ backgroundColor: '#ffffff', borderWidth: 1 }} />
                <p
                  style={{ fontSize: 56, fontWeight: 500 }}
                  className="pl-4 text-primary"
                >
                  $29.99
                  <span style={{ fontSize: 16, fontWeight: 300 }}>/mo</span>
                </p>
                <ul>
                  <li>
                    <b>Tableau Server & Tableau Online support</b>
                  </li>
                  <li>
                    Email and <b>chat support</b>
                  </li>
                  <li>
                    <b>Hosts proxy on separate or remote server</b>
                  </li>
                  <li>Saved credentials</li>
                  <li>Unlimited data imports</li>
                  <li>Access to unlimited Facebook Ads accounts</li>
                  <br />
                  <br />
                  <br />

                </ul>
                <div className="text-center py-5">
                  <Button
                    variant="primary"
                    href="https://profile.daexus.io/signup/fbads?plan=SERVER"
                  >
                    Sign up now
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  </>
);

export const pageQuery = graphql`
  query EnterpriseFBPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        button
        description
      }
    }
  }
`;
